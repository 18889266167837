import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth_google_url`);
      //console.log('Success:', response);
      const data = await response.data;
      // Redirect to Google login page
      window.location.href = data.url;
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(t('google login page loading error'));
    } finally {
      setLoading(false);
    }
  };

  // 处理 OAuth 回调
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code && state) {
      setLoading(true);
      // 构建请求体，只包含 code 和 state
      const requestBody = {
        code,
        state
      };

      // 使用 POST 请求发送数据
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth_google_callback`, requestBody)
        .then(async (response) => {
          console.log('认证成功，收到用户数据:', response.data);

          const result = response.data;
          sessionStorage.setItem('userId', result.user_id);
          sessionStorage.setItem('loggedIn', 'true');

          // 获取用户信息
          const userInfoResponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/user_info`,
            { user_id: result.user_id }
          );
          const userInfo = userInfoResponse.data;
          console.log(t('user_info'), userInfo);

          // 获取余额信息
          const balanceResponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/balance`,
            { user_id: result.user_id }
          );
          const balanceInfo = balanceResponse.data;
          console.log(t('balance_info'), balanceInfo);

          // 保存用户信息到 sessionStorage
          sessionStorage.setItem('userName', userInfo.user_name);
          sessionStorage.setItem('userEmail', userInfo.user_email);
          sessionStorage.setItem('balance', balanceInfo.balance);

          // 根据用户类型显示不同的欢迎消息
          if (result.message === "new user") {
            alert(t('Hello, {{name}}!', { name: userInfo.user_name }));
          } else if (result.message === "existing user") {
            alert(t('Welcome back, {{name}}!', { name: userInfo.user_name }));
          }

          // 清除 URL 参数并跳转到首页
          navigate('/');
          window.location.reload();
        })
        .catch(error => {
          console.error('认证过程出错:', error);
          setErrorMessage(t(error.response?.data?.message || 'Authentication failed'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [navigate, t]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErrorMessage(t('fill_all_fields')); // 替换硬编码文本
      return;
    }

    const data = {
      user_email: email,
      password: password
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, data);
      const result = response.data;

      console.log(t('login_response'), result);

      if (result.message === "login success") {
        sessionStorage.setItem('userId', result.user_id);
        sessionStorage.setItem('loggedIn', true);

        const userInfoResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user_info`, { user_id: result.user_id });
        const userInfo = userInfoResponse.data;

        console.log(t('user_info'), userInfo);

        const balanceResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/balance`, { user_id: result.user_id });
        const balanceInfo = balanceResponse.data;

        console.log(t('balance_info'), balanceInfo);

        sessionStorage.setItem('userName', userInfo.user_name);
        sessionStorage.setItem('userEmail', userInfo.user_email);
        sessionStorage.setItem('balance', balanceInfo.balance);

        alert(t('login_success')); // 替换硬编码文本
        navigate('/');
        window.location.reload();
      } else {
        setErrorMessage(t('login_failed')); // 替换硬编码文本
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data && error.response.data.error) {
        // 如果 error.message 是 "user email is not exist"
        if (error.response.data.error === "user email is not exist") {
          // 使用翻译函数 t() 来翻译特定的错误信息
          setErrorMessage(t('user_email_not_exist'));
        } else {
          // 如果是其他错误信息，保持原样
          setErrorMessage(error.response.data.error);
        }
      } else {
        console.error(t('login_error_console'), error);
        setErrorMessage(t('try_later')); // 替换硬编码文本
      }
    }

  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="bg-white p-5 rounded shadow">
            <h1 className="text-center">{t('login')}</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <div className="d-flex justify-content-center mb-4">
              <button
                onClick={handleGoogleLogin}
                className="btn d-flex align-items-center justify-content-center gap-2 w-100 border rounded py-2 mb-3 position-relative"
                style={{
                  backgroundColor: '#fff',
                  color: '#757575',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                  transition: 'background-color 0.3s'
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#fff'}
              >
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
                  <path fill="none" d="M0 0h48v48H0z" />
                </svg>
                <span className="fw-medium">{t('Continue with Google')}</span>
              </button>
            </div>

            <form className="needs-validation" noValidate onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">{t('email')}：</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">{t('password')}：</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">{t('login')}</button>
            </form>
            <div className="mt-3 text-center">
              <span>{t('new_user')}? </span>
              <Link to="/register" className="text-primary">{t('click_here_register')}</Link>
            </div>
            <div className="mt-1 text-center">
              <span>{t('forgot_password')}? </span>
              <Link to="/change_password" className="text-primary">{t('click_here_change')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
